.darkModeSwitch{
    display: flex;
    justify-content: flex-end;
    margin: 20px;
}

body.dark-mode {
    background-color: #0d161d;
    color: #fff;
}

body.light-mode{
    background-color: #dfdfdf;
    font-family: 'Orbitron', sans-serif;
    color: black;
}

body{
    background-color: #0d161d;
    font-family: 'Orbitron', sans-serif;
    color: #fff;
}

.darkSpan{
    color: white;
    display: flex;
    align-items: center;
    margin-right: 1rem;
    border: 2px solid #ec1c24;
    padding: 4px;
}

.lightSpan{
    color: black;
    display: flex;
    align-items: center;
    margin-right: 1rem;
    border: 2px solid #ec1c24;
    padding: 4px;
}
