.header{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ec1c24;
    border-top: 2px solid #000;
    border-bottom: 2px solid #000;
}

.headerSpan{
    margin-right: 10px;
    font-size: 46px;
    font-weight: bold;
    color: black;
}

.headerImg{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    margin-left: 10px;
    margin: 10px;
    border: 3px solid #000;
}

a.btn{
    display: inline-block;
    padding: 8px 16px;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    background-color: #ec1c24;
    color: black;
    border: 2px solid #000;
    font-family: 'Orbitron', sans-serif;
    margin: 10px;
    font-weight: bold;
}

a.btn:hover{
    background-color: black;
    color: white;
}

.links{
    display: flex;
    justify-content: center;
}
