.container{
    margin-top: 50px;
}

.infoLabel{
    display: flex;
    justify-content: center;
    margin: auto;
    width: 50%;
    font-size: 36px;
    font-weight: bold;
    border: 4px solid #ec1c24;
    padding: 1rem;
    font-family: 'Orbitron', sans-serif;
}

.infoLabel:hover{
    background-color: #ec1c24;
    color: #0d161d;
    cursor: pointer;
}

.infoContent{
    width: 50%;
    margin: auto;
    border: 4px solid #ec1c24;
    margin-top: 0;
    padding: 1rem;
}

.hide{
    display: none;
}

.blink{
    animation: blink 2s infinite;
}

@keyframes blink {
    50% {
        opacity: 0;
    }
}

.highlight{
    color: red;
}

.portImgContainer{
    text-align: center;
}

.portImg{
    width: 60%;
}

.projectLinks{
    display: flex;
    justify-content: space-evenly;
}

.btnProject{
    padding: 4px 16px;
    font-size: 16px;
    text-decoration: none;
    background-color: #ec1c24;
    color: black;
    border: 2px solid #000;
    font-family: 'Orbitron', sans-serif;
    margin: 10px;
}
